<template>
  <div class="projects">
    <div class="project">
      <!-- header -->
      <div v-if="currentProject.articleImage[0] != undefined" class="project__header_image">
        <img alt="Header Image" v-bind:src="currentProject.articleImage[0].url">  
      </div>
      <div v-if="currentProject.articleImage[0] == undefined" class="project__header_image">
        <img alt="Header Image" src="@/assets/images/header_home.png">  
      </div>
      <div class="project__header_content_box shadow-box">
        <h4>{{store.formatDate(currentProject.postDate)}}</h4>
        <h2>{{currentProject.headline}}</h2>
      </div>    
      <!--content -->
      <span class="project__content" v-html="currentProject.pageContent"></span>
      <router-link id="projectsDetail" class="bm-item-small next-link" to="/projects">Zurück zur Übersicht</router-link>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Projects',
  components: {
  },
  data() {
    return {
      store,
    };
  },
  computed: {
    currentProject: function () {
      return this.store.getCurrentSelectedProject();
    }
  },
}
</script>

<style lang="scss">
  
</style>
